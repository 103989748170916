<template>
  <b-card style="height:11cm !important"
    class="earnings-card"
  >
    <b-row>

      <b-col cols="12">
          <!-- <flat-pickr class="form-control" v-model="date" :config="{
            dateFormat: 'd/m/Y',
            mode: 'range',
          }"  placeholder="Select Date" style="background-color: transparent" 
          /> -->
    <div id="chart">
      <vue-apex-charts height="350" :options="chartOptions" :series="series"/>
    </div>

      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

const $earningsStrokeColor1 = '#6c5dd345'
const $earningsStrokeColor2 = '#f65160b8'
const $earningsStrokeColor3 = '#2b78eba8'
export default {
  components: {
    VueApexCharts,
    flatPickr
  },
  props:['data'],
  data(props) {
    return {
      date:new Date(),
      series: [],
        
        chartOptions: {
          chart: {
            type: 'area',
            height: 350,
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight'
          },
          
          title: {
            text: 'User Score Trend',
            align: 'left'
          },
          subtitle: {
            text: '',
            align: 'left'
          },
          legend: {
            horizontalAlign: 'left'
          }
        },
    }
  },
  mounted(){
    let first = []
      let second = []
      let third = []
      this.data.zero_value.map((i,index) => {
        let obj ={
          x:this.data.dates[index],
          y:i
        }
        first.push(obj)
      }) 
       this.data.between_value.map((i,index) => {
        let obj ={
          x:this.data.dates[index],
          y:i
        }
        second.push(obj)
      })
      this.data.above_value.map((i,index) => {
        let obj ={
          x:this.data.dates[index],
          y:i
        }
        third.push(obj)
      })
    this.series=[{
      name:'User Score 0',
      data:first,
          color:'#FF5733'
    },{
      name:'User Score Between 1 to 10',
      data:second,
          color:'#FF5733'
    },{
      name:'User Score Above 10',
      data:third,
          color:'#FF5733'
    }]
    this.$forceUpdate();
      }
}
</script>
<style>
</style>