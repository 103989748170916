<template>
    <b-card style="height:11cm !important"
      class="earnings-card"
    >
      <b-row>
        <b-col cols="12">
          <vue-apex-charts
            height="370"
            :options="earningsChart.chartOptions"
            :series="earningsChart.series"
          />
        </b-col>
      </b-row>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BCardTitle, BCardText,
  } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import { $themeColors } from '@themeConfig'
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';
  const $earningsStrokeColor1 = '#6c5dd345'
  const $earningsStrokeColor2 = '#f65160b8'
  const $earningsStrokeColor3 = '#2b78eba8'
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BCardTitle,
      BCardText,
      VueApexCharts,
    },
      props:['data'],
    data(props) {
      return {
        earningsChart: {
          series: [parseInt(props.data.crUsersCounts.count_one),parseInt(props.data.crUsersCounts.count_two),parseInt(props.data.crUsersCounts.count_three)],
          chartOptions: {
            chart: {
              type: 'donut',
              toolbar: {
                show: true,
  
              },
            },
            title: {
              text: 'User Score',
              align: 'left'
            },
            dataLabels: {
              enabled: false,
            },
            legend: { show: true ,position:'bottom'},
            comparedResult: [2, -3, 8],
            labels: ['New Score 0 Users', 'New Score Between 1 and 10 Users','New Score Above 10 Users'],
            stroke: { width: 0 },
            colors: [$earningsStrokeColor2, $earningsStrokeColor3,$earningsStrokeColor1],
            plotOptions: {
              pie: {
                startAngle: 0,
                donut: {
                  labels: {
                    show: true,
                    name: {
                      offsetY: 15,
                    },
                    value: {
                      offsetY: 20,
                      formatter(val) {
                        // eslint-disable-next-line radix
                        return `${parseInt(val)}`
                      },
                    },
                    total: {
                      show: true,
                      offsetY: 0,
                      label: 'Total',
                      formatter() {
                        return parseInt(props.data.crUsersCounts.count_one)+parseInt(props.data.crUsersCounts.count_two)+parseInt(props.data.crUsersCounts.count_three)
                      },
                    },
                  },
                },
              },
            },
          },
        },
      }
    },
  }
  </script>
  <style>
  </style>