<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h1 class="text-black">Users{{ search }}{{ app }}</h1>
      <div>
        <!-- <button class="btn notofication-btn mr-1">
          <img src="../../../assets/images/client/filter.svg" alt="Snow" />  
          </button> -->

        <button id="popover-button-bottom" href="#" tabindex="0" type="button" class="btn notofication-btn mr-1">
          <div>
            <img src="../../../assets/images/client/filter.svg" alt="Snow" />
          </div>
        </button>
        <b-popover ref="popover" target="popover-button-bottom" triggers="hover" placement="Left"
          class="custom-popover pop-btn" :show.sync="popoverShow1">
          <div class="" style="border: none;width:8cm">
            <flat-pickr v-model="date" :config="{
              dateFormat: 'd/m/Y',
              static:true,
              mode: 'range',
              inline:true
            }" @input="getFilterData($event)" />
              <button @click="date = ''" class="btn btn-sm btn-primary mt-1 float-right">Clear</button>
            <!-- <b-form-datepicker id="example-datepicker" v-model="filter" class="mb-2"></b-form-datepicker> -->
            <!-- <v-select  v-model="filter" class="" @input="handelChangeFilter" shadow placeholder="Select" :options="['All','Active','Inactive']"> -->
            <!-- </v-select> -->
          </div>
        </b-popover>

        <button type="button" v-b-tooltip.hover.v-primary @click="exportToCSV" title="Export CSV"
          variant="outline-primary" class="btn notofication-btn mr-1">
          <img src="../../../assets/images/client/export.svg" alt="Snow" />
        </button>
        
        <b-modal id="showmodal" ref="showmodal" class="m-2 custom--model">
          <template #modal-header>
            <div style="width:80%;padding: 10px 20px;" class="form-row">
              <div class="col-md-12">
                <h2>User </h2>
              </div>
            </div>
            <b-button @click="$refs.showmodal.hide()" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          
          <b-row class="p-1">
            <b-col md="6" class="d-flex">
              <div>
                <div class="mb-half">Id</div>
                  <p class="font-weight-bolder h5 mt-1">{{ showItem.id }}</p>
              </div>
            </b-col>
            <b-col md="6" class="d-flex">
              <div>
                <div class="mb-half">User Name</div>
                  <div class="font-weight-bolder h5 text-black mb-half">
                    {{ showItem.user_name ? showItem.user_name : '(not set)'  }}
                  </div>
                  </div>
            </b-col>
            <b-col md="6" class="d-flex">
              <div>
                <div class="mb-half">Email</div>
                  <p class="font-weight-bolder h5 mt-1">{{ showItem.email }}</p>
              </div>
            </b-col>
            <b-col md="6" class="d-flex">
              <div>
                <div class="mb-half">Password </div>
                  <p class="font-weight-bolder h5 mt-1">{{ showItem.password }}</p>
              </div>
            </b-col>
            <b-col md="6" class="d-flex">
              <div>
                <div class="mb-half">Token</div>
                  <p class="font-weight-bolder h5 mt-1">{{ showItem.token ? showItem.token.length > 40 ? showItem.token.slice(0,40) : showItem.token :''}}</p>
              </div>
            </b-col>
            <b-col md="6" class="d-flex">
              <div>
                <div class="mb-half">Authentication Token</div>
                  <p class="font-weight-bolder h5 mt-1">{{ showItem.auth_token ? showItem.auth_token.length > 40 ? showItem.auth_token.slice(0,40)+'...' : showItem.auth_token : ''}}</p>
              </div>
            </b-col>
            <b-col md="6" class="d-flex">
              <div>
                <div class="mb-half">Identity Token</div>
                  <p class="font-weight-bolder h5 mt-1">{{ showItem.identity_token ? showItem.identity_token.length > 40 ? showItem.identity_token.slice(0,40)+'...' : showItem.identity_token : ''}}</p>
              </div>
            </b-col>
            <b-col md="6" class="d-flex">
              <div>
                <div class="mb-half">Type</div>
                  <p class="font-weight-bolder h5 mt-1">{{ showItem.type }}</p>
              </div>
            </b-col>
            <b-col md="6" class="d-flex">
              <div>
                <div class="mb-half">Referral Points</div>
                  <p class="font-weight-bolder h5 mt-1">{{ showItem.referral_points }}</p>
              </div>
            </b-col>
            <b-col md="6" class="d-flex">
              <div>
                <div class="mb-half">Unique Coupon Count</div>
                  <p class="font-weight-bolder h5 mt-1">{{ showItem.copy_coupon_count }}</p>
              </div>
            </b-col>
            <b-col md="6" class="d-flex">
              <div>
                <div class="mb-half">Modified</div>
                  <p class="font-weight-bolder h5 mt-1">{{ showItem.modified }}</p>
              </div>
            </b-col>
            <b-col md="6" class="d-flex">
              <div>
                <div class="mb-half">Created</div>
                <div class="font-weight-bolder h5 text-black">{{ showItem.created }}</div>
              </div>
            </b-col>
            <b-col md="6" class="d-flex">
              <div>
                <div class="mb-half">Last used / Activity</div>
                <div class="font-weight-bolder h5 text-black">{{ showItem.last_used }}</div>
              </div>
            </b-col>
            <b-col md="6">
                <div>
                    <div class="mb-half">Status</div>
                    <div class="font-weight-bolder text-black">
                        <span v-if="showItem.is_active" class="btn btn-outline-success btn-sm">Active</span>
                        <span v-else class="btn btn-outline-danger btn-sm">Inactive</span>
                    </div>
                </div>
            </b-col>
            </b-row>
          </b-modal>
      </div>
    </div>

    <b-row v-if="chartData.dates">
        <b-col md="6">
          <user-chart-1 :data="chartData"/>
        </b-col>
        <b-col md="6">
          <user-chart-2 :data="chartData"/>
        </b-col>
      </b-row>
    
    <div v-if="isLoading || graphLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div class="d-flex justify-content-center align-items-center" v-else-if="registrationData.length == 0 ">
      <img style="width:13cm; height:13cm" :src="host + '/NoDataFound.svg'" />
    </div>
    <div v-else class="drop-zone">
      <draggable v-model="registrationData" handle=".draggable-task-handle" tag="ul"
        class="todo-task-list media-list draggable-task-handle d-inline">
        <b-card no-body class="card-revenue-budget dash-total-card mb-2 drag-el" v-for="(item, index) in registrationData" @dblclick="handelShow(item)"
          :key="index">
          <b-row class="p-1">
            <b-col md="2" class="d-flex">
              <div>
                <b-form-checkbox v-if="popoverShow" v-model="checkBoxIds[item.id]" class="mr-0 mt-50" name="is-rtl" inline
                  @change="CheckBoxHandelClick(item.id)" />
              </div>
              <div class="ml-1  mr-1">
                <div class="font-weight-bolder text-black mb-half">
                  User Name
                </div>
                {{ item.user_name ? item.user_name : '(not set)' }}
              </div>
            </b-col>
            <b-col md="4" class="d-flex ">
              <div>
                <div class="font-weight-bolder text-black mb-half">
                  Email
                </div>
                <div style="max-width: 15cm;">{{ item.email }}</div>
              </div>
            </b-col>
            <b-col  md="2" class="d-flex align-items-center">
                <b-form-checkbox v-model="item.is_active ? true : false" :checked="item.is_active ? true : false" class="my-checkbox ml-1" name="check-button" @change="HandelClickCheckBox(item.id)" switch>
                  <span class="switch-icon-left">
                    Active
                  </span>
                  <span class="switch-icon-right">
                    Inactive
                  </span>
                </b-form-checkbox>
              </b-col>
            <!-- <b-col md="1" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Coupon Code</div>
                <div class="font-weight-bolder text-black">
                    {{ item.coupon_code}}
                </div>
              </div>
            </b-col> -->
            <b-col md="2" class="d-flex">
              <div>
                <div class="mb-half">Created At</div>
                <div class="font-weight-bolder text-black">{{ item.created }}</div>
              </div>
            </b-col>
            <b-col md="2" class="d-flex justify-content-center align-items-center">
              <button type="button" class="btn btn-primary" style="border-radius: 16px; padding: 8px 20px;"
                v-b-modal="'myModal'" 
            @click="handelShow(item)"> View</button>
            </b-col>
          </b-row>
          <!-- <b-popover
          :target="'popover-button-variant'+item.id"
          triggers="click"
          class="custom-popover"
          :show.sync="popoverShow3[item.id]"
        >
          <button
            @click="onPopovcerOpen3(item.id)"
            class="notofication-btn"
            style="color: #408dff; margin: 5px"
            size="sm"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn notofication-btn ml-1"
            @click="handelEdit(item.id)"
            v-b-modal.modal
            style="background: rgb(63 140 254 / 13%); margin: 8px"
          >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3f8cfe" class="bi bi-pencil-square" viewBox="0 0 16 16">
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
          </svg>
          </button>
          <button
            type="button"
            class="btn notofication-btn"
            @click="handelDelete(item.id)"
            style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px"
          >
            <img src="../../../assets/images/client/delete.png" alt="Snow" />
          </button>
        </b-popover> -->
        </b-card>
      </draggable>
      <div class="no-results" :class="{ show: !registrationData.length }"></div>
    </div>
    <button v-if="(!isLoading || !graphLoading) && registrationData.length" type="button" class="w-auto btn slide-btn">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }} - {{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon class="mx-1" icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control"
          style="width:70px" @input="pagechange" type="number" />
        <feather-icon class="mx-1" icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
      </div>
    </button>

    <button v-if="popoverShow && (!isLoading || !graphLoading)" id="seeall-btn" type="button" class="w-auto btn see-btn" @click="selectAllClick()">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ selectAllLabel }}</p>
      </div>
    </button>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import { BImg } from 'bootstrap-vue'
import json2csv from 'json2csv';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';
import UserChart1 from './UserChart1.vue';
import UserChart2 from './UserChart2.vue';
import 'flatpickr/dist/flatpickr.css';
import vSelect from 'vue-select';
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { getClientStoreData } from '@/api/clientstore';
import { codeListGroupAction } from '@/views/components/list-group/code';
export default {
  components: {
    draggable,
    vSelect,
    flatPickr,
    quillEditor, 
    BImg,
    UserChart1,
    UserChart2
  },
  data() {
    return {
      mainProps: {
        width: 70,
        height: 70,
        class: 'm1',
      },
      chartData:{},
      data: {},
      filter: 'All',
      selectAllLabel: 'Select All',
      baseURL: process.env.VUE_APP_BASE_URL,
      host: window.location.origin,
      categoryOptions: [],
      registrationData: [],
      registrationData1: [],
      popoverShow: false,
      showItem:{},
      date:'',
      edit: false,
      popoverShow1: false,
      pageNo: 1,
      paginationStart: 0,
      isLoading: false,
      paginationEnd: 10,
      paginationLength: 0,
      clientNameEng: '',
      clientNameEArb: '',
      category: [],
      category_ids: [],
      descriptionEng: '',
      descriptionArb: '',
      status: '',
      sequence: '',
      checkBoxIds: {},
      websiteUrl: '',
      imageUrl: '',
      selectAll: false,
      keywordEng: '',
      popoverShow3: {},
      keywordArb: '',
      graphLoading:false
    };
  },
  created() {
    // document.addEventListener('scroll',this.handleScroll)
  },
  mounted() {
    setTimeout(() => {
      let is_valid = this.$store.state.sidebar.filter(data => data.name == 'Users')
      if(is_valid.length < 1){this.$router.push('/')}
    }, 500);
    this.getClientStore();
    this.getGraph();
    this.getClientCatgoriesData();
  },
  computed: {
    search() {
      // let data = []
      let search = this.$store.state.searchData
      // data = this.registrationData1.filter(item => (item.user_name?.replace(/\s/g,'').toLowerCase().includes(search) 
      //                                              || item.id.toString().includes(search) 
      //                                              || item.email.replace(/\s/g,'').toLowerCase().includes(search) 
      //                                              || item.created.replace(/\s/g,'').toLowerCase().includes(search)
      //                                              || (item.is_active == 1 ? 'Active' : 'Inactive').replace(/\s/g,'').toLowerCase().includes(search)
      //                                              ))
      this.getSearch(search);
      this.date = ''
    },
    app() {
      setTimeout(() => {
      let is_valid = this.$store.state.sidebar.filter(data => data.name == 'Users')
      if(is_valid.length < 1){this.$router.push('/')}
    }, 500);
      this.$store.state.application
      this.getClientStore()
    }
  },
  methods: {
    handleScroll(){
      this.popoverShow1 =false
    },
    handelShow(item){
      this.$refs.showmodal.show()
      this.popoverShow3={}
      this.showItem = item
    },
    getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      let date = this.date.toString().replace(/\s/g,'');
      let data = { 
          search: search, 
          pageno: this.pageNo, 
          app_id: this.$store.state.application.id,
          startDate:date ? date.includes('to') ? moment(date.split('to')[0], "DD/MM/YYYY").format("YYYY/MM/DD") : moment(date, "DD/MM/YYYY").format("YYYY/MM/DD") : null,
          endDate:date ? date.includes('to') ? moment(date.split('to')[1], "DD/MM/YYYY").format("YYYY/MM/DD") : null : null
        }
      this.$http.post(`${this.baseURL}/registration/search`, data,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.registrationData = res.data.data
          this.paginationLength = res.data.totaldata
          this.registrationData1 = res.data.data
          this.isLoading = false
        })
      // }
    }, 1000),
    getFilterData: _.debounce(function (e) {
      this.date = e
      this.getClientStore()
      this.getGraph()
    }, 1000),
    HandelClickCheckBox(id){
      this.$http.post(`${this.baseURL}/registration/enable-disable`,{id:id},
              {
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials:true
            }).then((res)=>{  
          console.log(res,'res')
          
        let data = { 
          pageno: 1, 
          app_id: this.$store.state.application.id,
          startDate:date ? date.includes('to') ? moment(date.split('to')[0], "DD/MM/YYYY").format("YYYY/MM/DD") : moment(date, "DD/MM/YYYY").format("YYYY/MM/DD") : null,
          endDate:date ? date.includes('to') ? moment(date.split('to')[1], "DD/MM/YYYY").format("YYYY/MM/DD") : null : null
        }
          this.$http.post( `${this.baseURL}/registration/index`,data).then((res)=>{
            this.registrationData = res.data.data
        this.paginationLength = res.data.totaldata
        this.registrationData1 = res.data.data
            this.statusUpdate()
          })
            })
    },
    exportToCSV() {
      // Convert the data to CSV format using json2csv library
      // let array = []
      // this.registrationData.map(item => {
      //   let data = {
      //   name_en:item.name_en,
      //   name_ar:item.name_ar,
      //   category: item.categories.map((item)=>{ return item.name}).join(", "),
      //   description_en:item.description_en,
      //   description_ar:item.description_ar,
      //   is_active:item.is_active,
      //   sequence:item.sequence,
      //   website:item.website,
      //   image:item.image,
      //   search_keyword_en:item.search_keyword_en,
      //   search_keyword_ar:item.search_keyword_ar,
      // }
      // array.push(data);
      // })
      const csvData = json2csv.parse(this.registrationData)
        ;
      const link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
      link.download = 'User.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    closeModal() {
      this.$refs['modal'].hide()
    },
    handelChangeFilter() {
      console.log(this.filter)
      if (this.filter == "All") {
        this.registrationData = this.registrationData1
      }
      else if (this.filter == "Active") {
        let data = []
        data = this.registrationData1.filter(item => item.is_active == 1)
        this.registrationData = data
      }
      else if (this.filter == "Inactive") {
        let data = []
        data = this.registrationData1.filter(item => item.is_active == 0)
        this.registrationData = data
      }
    },
    onPopovcerOpen3(id) {
      this.popoverShow3[id] = this.popoverShow3[id] == undefined ? false : this.popoverShow3[id];
      this.popoverShow3[id] = this.popoverShow3[id] == true ? false : true;
      this.$forceUpdate()
    },
    pagechange(){
      if(this.pageNo>(this.paginationLength/10)){
        this.pageNo = parseInt(this.paginationLength/10)
      }
      if(this.pageNo == 0){
        this.pageNo = 1
      }
        this.paginationStart = (this.pageNo*10)-10
        this.paginationEnd = (this.pageNo*10)
      let search = this.$store.state.searchData
          if (search) {
            this.searchPaginationHandelClick(search)
          } else {
            this.normalPaginationHandelClick(search)
          }
    },
    searchPaginationHandelClick: _.debounce(function (search) {
      this.isLoading = true
      let date = this.date.toString().replace(/\s/g,'');
      let data = { 
          search: search, 
          pageno: this.pageNo, 
          app_id: this.$store.state.application.id,
          startDate:date ? date.includes('to') ? moment(date.split('to')[0], "DD/MM/YYYY").format("YYYY/MM/DD") : moment(date, "DD/MM/YYYY").format("YYYY/MM/DD") : null,
          endDate:date ? date.includes('to') ? moment(date.split('to')[1], "DD/MM/YYYY").format("YYYY/MM/DD") : null : null
        }
      this.$http.post(`${this.baseURL}/registration/search`, data).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.registrationData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.registrationData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    normalPaginationHandelClick: _.debounce(function () {
      this.isLoading = true 
        let date = this.date.toString().replace(/\s/g,'');
      let data = { 
          pageno: this.pageNo, 
          app_id: this.$store.state.application.id,
          startDate:date ? date.includes('to') ? moment(date.split('to')[0], "DD/MM/YYYY").format("YYYY/MM/DD") : moment(date, "DD/MM/YYYY").format("YYYY/MM/DD") : null,
          endDate:date ? date.includes('to') ? moment(date.split('to')[1], "DD/MM/YYYY").format("YYYY/MM/DD") : null : null
        }
      this.$http.post(`${this.baseURL}/registration/index`,data).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.registrationData = res.data.data
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        // this.pageNo=res.data.page
        this.paginationLength = res.data.totaldata
        this.registrationData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    paginationHandelClick(value) {
      let search = this.$store.state.searchData
      if (value == 'back') {
        if (this.paginationStart > 1) {
          this.pageNo--
          this.paginationStart = this.paginationStart - 10
          this.paginationEnd = this.paginationEnd - 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
      else if (value == 'next') {
        if (this.paginationEnd < this.paginationLength) {
          this.pageNo++
          this.paginationStart = this.paginationStart + 10
          this.paginationEnd = this.paginationEnd + 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
    },
    HandelClickMultipleDelete() {
      let arr = []
      Object.keys(this.checkBoxIds).map((key) => {
        if (this.checkBoxIds[key] == true) {
          arr.push(key)
        }
      });
      this.$http.post(`${this.baseURL}/registration/multi-delete`, { ids: arr },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.$swal({
            title: "Deleted",
            text: res.data.message
              ? `${res.data.message}`
              : res.data.success
                ? `${res.data.success}`
                : "Deleted",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getClientStore()
          this.onPopovcerOpen3(id)
          this.popoverShow3[id] = false
          this.checkBoxIds = {}
          this.getClientStore()
          this.$forceUpdate()
        })
    },
    handelDelete(id) {
      this.$http.post(`${this.baseURL}/registration/delete`, { id: id },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.$swal({
            title: "Deleted",
            text: res.data.message
              ? `${res.data.message}`
              : res.data.success
                ? `${res.data.success}`
                : "Deleted",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getClientStore()
          this.onPopovcerOpen3(id)
          this.checkBoxIds = {}
          this.popoverShow3[id] = false
          this.getClientStore()
          this.$forceUpdate()
        })
    },
    selectAllClick() {
      let data = Object.keys(this.checkBoxIds).filter(item => this.checkBoxIds[item])
      console.log(data)
      if (data.length == 10) {
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
      } else {
        this.selectAllLabel = 'Deselect All'
        // console.log('enter',this.paginationStart,this.paginationEnd,this.registrationData,this.checkBoxIds)
        for (let x = this.paginationStart; x < this.paginationEnd; x++) {
          this.checkBoxIds[this.registrationData[x].id] = true
        }
      }
      this.$forceUpdate()
    },
    CheckBoxHandelClick(id) {
      // this.checkBoxIds[id] = this.checkBoxIds[id] == undefined ? false : this.checkBoxIds[id]
      // this.checkBoxIds[id] = this.checkBoxIds[id] == true ? false : true
      let data = Object.keys(this.checkBoxIds).filter(item => this.checkBoxIds[item])
      if (data.length == 10) {
        this.selectAllLabel = 'Deselect All'
      } else {
        this.selectAllLabel = 'Select All'
      }
      this.$forceUpdate()
    },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    onPopovcerOpen1() {
      this.popoverShow1 = !this.popoverShow1;
    },
    getGraph(){
      let data={}
      this.chartData={}
      this.graphLoading= true
      if(this.date){
        let array =  this.date.includes('to')?this.date.split(' to '):[this.date,this.date];
        data={
          startDate : array[0],
          endDate : array[1] 
        }
      }
        this.$http.post(`${this.baseURL}/registration/chart`,data).then((res) => {
            this.chartData = res.data
            this.graphLoading= false
        })
    },
    async getClientStore() {
      if (this.$store.state.application) {
        this.isLoading = true
        let date = this.date.toString().replace(/\s/g,'');
        let data = { 
          pageno: 1, 
          app_id: this.$store.state.application.id,
          startDate:date ? date.includes('to') ? moment(date.split('to')[0], "DD/MM/YYYY").format("YYYY/MM/DD") : moment(date, "DD/MM/YYYY").format("YYYY/MM/DD") : null,
          endDate:date ? date.includes('to') ? moment(date.split('to')[1], "DD/MM/YYYY").format("YYYY/MM/DD") : null : null
        }
        console.log(data,"data-=-=-")
        this.$http.post(`${this.baseURL}/registration/index`, data).then((res) => {
          console.log(res.data.data, "res-=-=")
          this.registrationData = res.data.data
          this.paginationLength = res.data.totaldata
          this.registrationData1 = res.data.data
          this.isLoading = false
        })
      }
      // let data = await getregistrationData();
      // console.log(data,"data=====");
    },
    getClientCatgoriesData(){
        this.$http.post(`${this.baseURL}/client-categories/index`).then((res) => {
          console.log(res.data.data, "res-=-=")
          this.categoryOptions = res.data.data
          this.checkBoxIds = {}
        })
      }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }

.success-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}

.danger-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #c90a0a;
  border: 1px solid #c90a0a;
  border-radius: 4px;
}

.mb-half {
  margin-bottom: 5px;
}

.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}

.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  width: 200px;
  float: right;
}

.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}

.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}

.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}

.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}

.popover-body {
  padding: 0.65rem 1.21rem !important;
  border: none !important;
}

.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}

.vs-select__options__content {
  background-color: #ffffff !important;
}

.pop-btn {
  padding: 0 !important;
}

.modal-dialog {
  max-width: 800px !important;
}


.modal-body {
  padding: 0px 40px 40px 40px !important
}
.form-control {
  border-radius: 14px;
}

.modal-content {
  border-radius: 24px;
}

.modal-header {
  border-radius: 24px;
}

textarea.form-control {
  min-height: 150px !important;
}

.popover1-body {
  padding: 0;
}

.modal-footer {
  display: none;
}

.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}
</style>
